import React, { useState, useEffect } from 'react';
import * as S from './FloatingCountdown.styles';

const FloatingCountdown = props => {
  const {
    children,
    discount,
    durationSeconds,
    position,
    showTimer,
    showCustomText,
    customText,
    addDelayTime,
    delayTime,
    addClosingTime,
    closingTime,
    showTransition,
    closeTransition,
    transitionSpeed,
    backgroundColor,
    textColor,
    hideCloseButton
  } = props;

  const [load, setLoad] = useState(!addDelayTime);
  const [visible, setVisible] = useState(true);
  const [duration, setDuration] = useState([durationSeconds, 0, 0]);
  const [transition, setTransition] = useState('');
  const triggerClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (showTimer) {
      const timer = setTimeout(() => {
        if (duration[0] <= 0) {
          setVisible(false);
        } else {
          const newDuration = duration[0] - 1;
          const minutes = Math.floor(duration[0] / 60);
          var seconds = duration[0] % 60;
          setDuration([newDuration, minutes, seconds]);
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [showTimer, duration]);

  useEffect(() => {
    if (!load && addDelayTime) {
      setTimeout(() => {
        setLoad(true);
        setVisible(true);
        setTimeout(() => {
          setTransition(showTransition ?? '');
        }, ((transitionSpeed ?? 1) + 1) * 1000);

        if (visible && addClosingTime) {
          setTimeout(() => {
            setTransition(closeTransition ?? '');
            setTimeout(() => {
              setVisible(false);
            }, ((transitionSpeed ?? 1) + 1) * 1000);
          }, (delayTime + closingTime) * 1000);
        }
      }, delayTime * 1000);
    }
  }, [
    addDelayTime,
    delayTime,
    addClosingTime,
    closingTime,
    load,
    setLoad,
    visible,
    setVisible,
    showTransition,
    closeTransition,
    transition,
    setTransition,
    transitionSpeed
  ]);

  const WithTimer = () => {
    return (
      <>
        <strong>{discount}%</strong> discount applied and reserved for you for
        the next{' '}
        <strong>
          {duration[1]} minutes {duration[2]} seconds
        </strong>
      </>
    );
  };

  const WithoutTimer = () => {
    return (
      <>
        <strong>{discount}%</strong> discount applied and reserved for you!{' '}
        <strong>Special Offer.</strong>
      </>
    );
  };

  const CustomText = () => {
    return {
      __html: customText
    };
  };

  const CloseButton = () => {
    return (
      !hideCloseButton && (
        <S.CloseButton
          className="close-button"
          onClick={triggerClose}
        ></S.CloseButton>
      )
    );
  };

  return load && visible ? (
    <S.Container className={`floating-countdown-container ${position}`}>
      {children ? (
        <S.Transition2
          className={transition}
          start={showTransition}
          speed={transitionSpeed}
        >
          <CloseButton />
          {children}
        </S.Transition2>
      ) : (
        <S.Transition
          className={transition}
          start={showTransition}
          speed={transitionSpeed}
          backgroundColor={backgroundColor}
          textColor={textColor}
        >
          <CloseButton />
          {showCustomText && <div dangerouslySetInnerHTML={CustomText()} />}
          {!showCustomText && (
            <>
              {showTimer && <WithTimer />}
              {!showTimer && <WithoutTimer />}
            </>
          )}
        </S.Transition>
      )}
    </S.Container>
  ) : (
    <></>
  );
};

export default FloatingCountdown;
